import { useMutation } from '@apollo/client';
import Layout from '@layouts';
import { getErrorMessages, getInvalidFields, SecurityContext } from '@lib';
import { useFormik } from 'formik';
import { graphql, PageProps } from 'gatsby';
import * as React from 'react';
import { FC, useContext, useEffect } from 'react';
import { toast } from 'react-hot-toast';

import { SendResetPasswordLinkDocument } from '@redsleeve/oilynx-domain';

import Button from '@components/Button';
import TextInput from '@components/form/TextInput';

import Menu from './Menu';

type PasswordPageProps = PageProps & {
  data: { site: { siteMetadata: { siteUrl: string } } };
};

const PasswordPage: FC<PasswordPageProps> = ({
  location,
  data: {
    site: { siteMetadata },
  },
}) => {
  const { currentUser } = useContext(SecurityContext);
  const [sendRequestPass] = useMutation(SendResetPasswordLinkDocument);

  const formik = useFormik({
    initialValues: { email: '' },
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      setSubmitting(true);
      const toastId = toast.loading('Resetting password...');

      try {
        await sendRequestPass({
          variables: {
            email: values.email,
            origin: siteMetadata.siteUrl,
          },
        });

        toast.success(
          `You will receive a reset password reset link on ${values.email}`,
          { id: toastId, duration: 60 * 1000 }
        );
      } catch (ex) {
        const formError = getInvalidFields(ex);
        if (formError) setErrors(formError);
        const errorMessages = getErrorMessages(ex);
        toast.error(errorMessages?.join('\n') ?? 'Failed to reset password', {
          id: toastId,
          duration: 10 * 1000,
        });
        if (!errorMessages)
          console.error('[Request reset password page] onSubmit', ex);
      } finally {
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    if (currentUser?.email) formik.setFieldValue('email', currentUser.email);
  }, [currentUser?.email]);

  return (
    <Layout url="/profile/password/" title="Password" disableIndex>
      <Menu currentPath={location.pathname}>
        <form
          className="flex-1 flex flex-col mb-0"
          onSubmit={formik.handleSubmit}
        >
          <div className="lg:pl-12 flex-1 flex flex-col">
            <h3 className="hidden lg:block font-emp text-2xl">Password</h3>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-16 gap-y-4">
              <p className="font-emp text-sm col-span-1 col-start-1">
                Enter the email address and we will send you instructions to
                reset your password.
              </p>
              <p className="font-emp text-sm col-span-1 col-start-1">
                We will never send your password via email.
              </p>

              <TextInput
                name="email"
                formik={formik}
                label="Email"
                className="col-span-1 col-start-1"
                disabled
              />
            </div>
            <div className="flex-1" />
            <div className="p-4 -mx-4 mt-8 lg:mt-20 lg:bg-transparent">
              <Button
                className="w-full"
                type="submit"
                variant="primary"
                disabled={formik.isSubmitting}
              >
                Request Password Reset
              </Button>
            </div>
          </div>
        </form>
      </Menu>
    </Layout>
  );
};

export const query = graphql`
  query PasswordPageQuery {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;

export default PasswordPage;
